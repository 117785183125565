<template>
<span>

    <v-toolbar flat color="white">
        <v-toolbar-title class="lime--text lighten-1">Gerenciar Newsletters</v-toolbar-title>
        <v-divider class="mx-2" inset vertical></v-divider>
        <v-spacer></v-spacer>
        <template>
            <vue-json-to-csv :json-data="loadNewsletters" :csv-title="'newsletters'" :separator="';'" :labels="{ news_nome:{ title: 'Nome' }, news_email:{title: 'Email'} }">
                <v-btn color="blue-grey darken-1" dark class="mb-1 mr-3">
                    <b>Exportar para CSV</b>
                </v-btn>
            </vue-json-to-csv>
            <v-btn color="blue-grey darken-2" dark class="mb-1" @click="atualizarDados">
                <v-icon dark>sync</v-icon>Atualizar
            </v-btn>
        </template>
    </v-toolbar>

    <v-card>
        <v-card-title>
            <v-text-field v-model="search" append-icon="search" label="Localizar" single-line hide-details></v-text-field>
        </v-card-title>
        <v-data-table :headers="headers" :items="loadNewsletters" :search="search" :rowsPerPageItems="rowsPerPageItems">



            <template v-slot:no-results>
                <v-alert :value="true" color="error" icon="warning">
                    Voce procurou por "{{ search }}" nenhum item localizado.
                </v-alert>
            </template>
        </v-data-table>
    </v-card>

</span>
</template>

<script>
import VueJsonToCsv from 'vue-json-to-csv'
import {
    URL_BASE
} from '../../../config/configs'

export default {
    created() {
        this.$store.dispatch('loadNewsletters');
    },
    computed: {
        loadNewsletters() {

            if (this.$store.state.newsletters.items.data == null)
                return []

            return this.$store.state.newsletters.items.data;
        }
    },
    name: "NewsletterComponent",
    data() {
        return {
            search: '',
            rowsPerPageItems: [10, 20, 30, 50, {
                "text": "$vuetify.dataIterator.rowsPerPageAll",
                "value": -1
            }],
            headers: [{
                    sortable: false,
                    text: 'Nome',
                    value: 'news_nome'
                },
                {
                    sortable: false,
                    text: 'Email',
                    value: 'news_email'
                }
            ],
            base_url: URL_BASE
        }
    },
    methods: {
        atualizarDados() {
            this.$store.dispatch('loadNewsletters');
        },
        onChangeEventHandlertStatus(news_id, value) {
            this.$store.dispatch('updateNewsletterStatus', {
                    news_id: news_id,
                    news_status: value
                })
                .then(() => {
                    this.$swal({
                        position: "center",
                        icon: "success",
                        title: 'Sucesso',
                        text: 'Status atualizado com sucesso',
                        showConfirmButton: true,
                        timer: 6000
                    });
                })
                .catch(error => {
                    this.$swal({
                        position: "center",
                        icon: "error",
                        title: 'Opssss!',
                        text: 'Erro ao atualizar status',
                        showConfirmButton: true,
                        timer: 6000
                    });
                })
        },
    },
    components: {
        VueJsonToCsv
    },
};
</script>

<style scoped>
.avatar {
    height: 40px;
    margin-top: 0.4rem;
}

.foto-ampliada {
    max-width: 100%
}
</style>
